import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Box, Typography, Grid, styled, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { toast } from 'react-hot-toast';

import InputButton from '@/components/Button';
import { useAuth } from '@/hooks';
import logo from '@/assets/logo.png';
import TextInput from '@/components/TextInput';
import { useRequestTFaMutation } from '@/api/tfa';
import { TFA_MEDIUM } from '@/api/tfa/types';
import { UserType } from '@/api/auth/types';

import Schema from './schema';
import LoginActions from './components/LoginActions';

const VefifyTfaSchema = z.object({
  otp: z.string().min(6, { message: ' ' }),
  key: z.string().min(1, { message: ' ' }),
  type: z.number()
});

const Container = styled(Box)({
  position: 'relative',
  height: '100vh'
});

const Image = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '60px'
});

const FormBox = styled('form')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

const Login = () => {
  const LoginSchema = Schema.required();
  const navigate = useNavigate();

  const { login, loginViaTfaVerification } = useAuth();
  const { mutate: requestTfa, isPending: isRequestingOTP } = useRequestTFaMutation();

  const {
    handleSubmit: handleSubmitLoginForm,
    control: loginFormControl,
    formState: { isSubmitting: isSubmittingLoginForm }
  } = useForm<z.infer<typeof LoginSchema>>({
    defaultValues: {
      username: '',
      password: ''
    },
    resolver: zodResolver(LoginSchema)
  });

  const {
    handleSubmit: handleSubmitVerifyTfaCode,
    setValue: setVeriyTfaFormValue,
    watch: watchVerifyTfaFormValue,
    reset: resetVerifyTfaForm,
    formState: { isSubmitting: isVerifyingTfa }
  } = useForm<z.infer<typeof VefifyTfaSchema>>({
    defaultValues: {
      otp: '',
      key: '',
      type: TFA_MEDIUM.AUTHENTICATOR_APP
    },
    resolver: zodResolver(VefifyTfaSchema)
  });

  const tfaVerificationKey = watchVerifyTfaFormValue('key');
  const verificationCode = watchVerifyTfaFormValue('otp');
  const type = watchVerifyTfaFormValue('type');
  const isTfaRequired = Boolean(tfaVerificationKey);

  const onSubmitLoginForm: SubmitHandler<z.infer<typeof LoginSchema>> = async (data) => {
    await login(data, (response) => {
      if (response.data?.content?.usertype === UserType.ISSUER_GUEST) {
        // user hasn't created their profile yet (onboarding)
        navigate('/onboarding', {
          state: {
            onboardingData: response.data
          }
        });
      } else if (response?.data?.content?.isTFARequired) {
        setVeriyTfaFormValue('key', response?.data?.content?.key);
      }
    });
  };

  const onSubmitVerifyTfaCode: SubmitHandler<z.infer<typeof VefifyTfaSchema>> = async (data) => {
    await loginViaTfaVerification(data);
  };

  return (
    <Container>
      {isTfaRequired && (
        <FormBox
          sx={{
            width: { xs: '80%', md: '400px' },
            paddingX: { md: 0, xs: '20px' }
          }}
          onSubmit={handleSubmitVerifyTfaCode(onSubmitVerifyTfaCode)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" rowGap={2}>
            <Image>
              <img src={logo} alt="Logo" />
            </Image>
            <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
              Get OTP from Authenticator App (such as <b>Google Authenticator</b>, or <b>Authy</b>)
            </Typography>

            <Box mt={2}>
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                Enter Verification Code
              </Typography>
              <OtpInput
                value={verificationCode}
                onChange={(val) => setVeriyTfaFormValue('otp', val)}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    style={{
                      width: '50px',
                      height: '50px',
                      fontSize: '16px',
                      textAlign: 'center',
                      border: '1px solid #F56329',
                      borderRadius: '5px',
                      margin: '5px'
                    }}
                  />
                )}
              />
              <RadioGroup
                row
                value={type}
                onChange={(e) => {
                  setVeriyTfaFormValue('type', e.target.value as any);
                }}
                sx={{ marginTop: '10px' }}
              >
                <FormControlLabel
                  value={TFA_MEDIUM.AUTHENTICATOR_APP}
                  control={<Radio size="small" />}
                  label="Use Authenticator App"
                  sx={{
                    '.MuiTypography-root': { fontSize: '13px', fontFamily: 'lato, sans-serif' }
                  }}
                />
                <FormControlLabel
                  value={TFA_MEDIUM.Email}
                  control={<Radio size="small" />}
                  label="Use Email"
                  sx={{
                    '.MuiTypography-root': { fontSize: '13px', fontFamily: 'lato, sans-serif' }
                  }}
                />
              </RadioGroup>
            </Box>
            <Box width="100%" display="flex" flexDirection="column">
              <InputButton
                fullWidth
                disabled={verificationCode.length < 6}
                loading={isVerifyingTfa}
                success
              >
                Verify
              </InputButton>
              <InputButton
                type="button"
                fullWidth
                variant="outlined"
                disabled={isRequestingOTP || isVerifyingTfa}
                onClick={() => {
                  resetVerifyTfaForm();
                }}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#F56329',
                  border: '1px solid #F56329',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: '1px solid #F56329'
                  }
                }}
              >
                Go Back
              </InputButton>
            </Box>
            <Box>
              <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>OR</Typography>
              <InputButton
                type="button"
                variant="text"
                fullWidth
                loading={isRequestingOTP}
                disabled={isVerifyingTfa || isRequestingOTP}
                onClick={() => {
                  requestTfa(
                    { key: tfaVerificationKey, type: TFA_MEDIUM.Email },
                    {
                      onSuccess: (res) => {
                        toast.success(res?.success?.message);
                        setVeriyTfaFormValue('type', TFA_MEDIUM.Email);
                      },
                      onError: (err) => {
                        toast.error(err?.data.error.message);
                      }
                    }
                  );
                }}
                sx={{
                  textTransform: 'initial',
                  backgroundColor: '#fff',
                  color: '#F56329',
                  '&:hover': {
                    backgroundColor: '#fff'
                  }
                }}
              >
                Send OTP to Configured Email
              </InputButton>
            </Box>
          </Box>
        </FormBox>
      )}
      {!isTfaRequired && (
        <FormBox
          sx={{
            width: { xs: '80%', md: '400px' },
            paddingX: { md: 0, xs: '20px' }
          }}
          onSubmit={handleSubmitLoginForm(onSubmitLoginForm)}
        >
          <Box sx={{ marginBottom: '20px' }}>
            <Image>
              <img src={logo} alt="Logo" />
            </Image>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
              Sign in
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 400 }}>
              Enter your details below
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="username"
                control={loginFormControl}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => {
                  return <TextInput label={'Username'} type="text" error={error} {...field} />;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={loginFormControl}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => {
                  return <TextInput label={'Password'} type="password" error={error} {...field} />;
                }}
              />
            </Grid>
          </Grid>
          <LoginActions>
            <InputButton fullWidth loading={isSubmittingLoginForm}>
              Login
            </InputButton>
          </LoginActions>
        </FormBox>
      )}
    </Container>
  );
};

export default Login;
